<template>
  <div v-if="!lock" style="position: absolute">
    <div
      v-if="countdown > 0"
      :class="{
        'notification t-center d-flex': true,
        [type]: true,
      }"
    >
      <button v-if="dismissible" class="notification-close" @click="turnOff">
        x
      </button>
      <span class="notification-message m-auto"> {{ notificationMessage }} </span>
    </div>
  </div>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex'

	export default {
		name: 'Notification',
		props: {
			dismissible: {
				type: Boolean,
				default: true,
			},
		},
		computed: {
			...mapGetters('notifications', ['notificationMessage', 'countdown', 'type', 'lock']),
		},
		mounted() {
			window.onbeforeunload = this.turnOff
		},
		methods: {
			...mapMutations('notifications', ['hideNotification']),
			turnOff() {
				this.hideNotification()
			},
		},
	}
</script>
<style lang="scss">
	.notification {
		margin-bottom: 0;
		padding: 10px 20px;
		position: fixed;
		left: 0;
		z-index: 90;
		min-height: 50px;
		width: 100%;
		font-family: 'Apercu';
		font-weight: bold;
		color: #ffffff;
		-webkit-box-shadow: 0px 4px 0px 0px rgba(214, 211, 214, 0.2);
		-moz-box-shadow: 0px 4px 0px 0px rgba(214, 211, 214, 0.2);
		box-shadow: 0px 4px 0px 0px rgba(214, 211, 214, 0.2);
		&.ok {
			background-color: #3f8a93;
		}
		&.error {
			background-color: #c41d1d;
		}
    &.cs{
			background-color: #a6d5d5;
      color: black;
    }
		.notification-close {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
			padding: 0.75rem 1.25rem;
			color: inherit;
		}
	}

	/* Extra small devices (phones, 600px and down) */
	@media only screen and (max-width: 600px) {
		.notification-close,
		.notification-message {
			font-size: 14px;
		}
	}

	/* Small devices (portrait tablets and large phones, 600px and up) */
	@media only screen and (min-width: 601px) {
		.notification-close,
		.notification-message {
			font-size: 16px;
		}
	}

	/* Medium devices (landscape tablets, 768px and up) */
	@media only screen and (min-width: 768px) {
		.notification-close,
		.notification-message {
			font-size: 17px;
		}
	}

	/* Large devices (laptops/desktops, 992px and up) */
	@media only screen and (min-width: 992px) {
		.notification-close,
		.notification-message {
			font-size: 20px;
		}
	}
</style>
